;globalThis["__sentryRewritesTunnelPath__"] = undefined;globalThis["SENTRY_RELEASE"] = {"id":"ff19ee3b373349487677b0c33535d9bc4634f0b8"};globalThis["__sentryBasePath"] = undefined;globalThis["__rewriteFramesAssetPrefixPath__"] = "";import { init, browserTracingIntegration, replayIntegration } from '@sentry/nextjs';

const integrations = [
  browserTracingIntegration({
    tracePropagationTargets: ['localhost', /\.herokuapp.com/, /\.raylo\.com/],
  }),
];

if (process.env.NEXT_PUBLIC_SENTRY_REPLAYS_ENABLED === 'true') {
  integrations.push(
    replayIntegration({
      maskAllText: false,
      maskAllInputs: true,
      blockAllMedia: false,
    }),
  );
}

init({
  ignoreErrors: [
    // Random plugins/extensions
    'top.GLOBALS',
    // Outlook safe-link crawlers
    'Non-Error exception captured',
    'Non-Error promise rejection captured',
    // "Block third-party cookies and site data" checkbox is set
    "Failed to read the 'sessionStorage' property from 'Window': Access is denied for this document",
  ],
  denyUrls: [
    // netlify
    'https://ingesteer.services-prod.nsvcs.net/rum_collection',
    // clarity
    'https://p.clarity.ms/collect',
    // Chrome extensions
    /extensions\//i,
    /^chrome:\/\//i,
    /^chrome-extension:\/\//i,
    // safari webkit
    /@webkit-masked-url/,
    // Ignore Google flakiness
    /\/(gtm|ga|analytics)\.js/i,
    /\/gtag\/js/i,
  ],
  dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
  environment: process.env.NEXT_PUBLIC_SENTRY_ENVIRONMENT,
  tracesSampleRate: parseFloat(process.env.NEXT_PUBLIC_SENTRY_TRACES_SAMPLE_RATE_FLOAT) || 0.01,
  replaysOnErrorSampleRate:
    parseFloat(process.env.NEXT_PUBLIC_SENTRY_REPLAYS_ON_ERROR_SAMPLE_RATE_FLOAT) || 1.0,
  integrations,
});
